h1, p {
    font-family: Lato;
}

.cardStep {
    transition: all 300ms ease;
    width: 100%;
    overflow: hidden;
    /* margin-left: 1%; */
    /* margin-top: 8px; */
    /* height: 97%; */
}

.BtnProxPassoDoc {
    margin-top: 100px;
}



.tabsEstilo {
    /* overflow-y: scroll; */
    /* height: 23rem; */
    border: 1px solid #dee2e6;
}

.tabsEstilo::-webkit-scrollbar-track
{
    background-color: #ddd;
}

.tabsEstilo::-webkit-scrollbar
{
    width:7px;
    background-color: #ddd;
    border-radius: 10px;
}

.tabsEstilo::-webkit-scrollbar-thumb
{
    background-color: #0a71b2;
    border-radius: 10px;
}

.proxPassoDoc {
    margin-top: 5px;
}

.tabsEstiloDocumentacao {
    /* width: 1300px; */
    padding-right: 10px;
    /* width: 100%; */
    /* height: 340px; */
    border: 1px solid #dee2e6;
    /* overflow-y: scroll;
    overflow-x: hidden; */
}

.tabsEstiloDocumentacao::-webkit-scrollbar-track
{
    background-color: #ddd;
}

.tabsEstiloDocumentacao::-webkit-scrollbar
{
    width: 7px;
    background-color: #ddd;
    border-radius: 10px;
}

.tabsEstiloDocumentacao::-webkit-scrollbar-thumb
{
    background-color: #0a71b2;
    border-radius: 10px;
}

.SpanAddComprador{
    color: rgb(116, 116, 121);
}

@media (max-width: 1024px){
    .bs-stepper-header::-webkit-scrollbar-thumb{
        background-color: #0a71b2;
        border-radius: 10px;
    }

    .bs-stepper-header::-webkit-scrollbar-track{
    background-color: #ddd;
    
    }

    .bs-stepper-header::-webkit-scrollbar {
        background-color: #ddd;
        border-radius: 10px;
        height: 5px;
    }

    .bs-stepper-header{
        overflow-x: scroll;
        margin-bottom: 5px;
    }
    
    .tabsEstiloDocumentacao {
        /* height: 280px; */
        /* overflow-x: hidden !important; */
    }

    .proxPassoDoc {
        margin-top: 30px;
    }

    .tabsEstiloFluxo {
        /* overflow-x: scroll; */
        /* height: 700px; */
    }
  
    img {
        display: none;
    }
}

/* @media (min-width: 768px) and (max-width: 1024px){
    .bs-stepper-header::-webkit-scrollbar-thumb{
        background-color: #0a71b2;
        border-radius: 10px;
    }

    .bs-stepper-header::-webkit-scrollbar-track{
    background-color: #ddd;
    
    }

    .bs-stepper-header::-webkit-scrollbar {
        background-color: #ddd;
        border-radius: 10px;
        height: 5px;
    }

    .bs-stepper-header{
        overflow-x: scroll;
        margin-bottom: 5px;
    }

    .tabsEstiloDocumentacao {
        height: 680px;
        overflow-x: hidden !important;
    }

    .tabsEstiloFluxo {
        overflow-x: scroll;
        height: 700px;
    }
    .proxPassoDoc {
        margin-top: 30px;
    }
  
    img {
        display: none;
    }
    
} */