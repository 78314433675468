.tabela-dommus thead {
    font-size: 14px;
}

.card.card-table {
    display: block;
    border: 0;
    overflow: auto;
    margin-bottom: 0;

}

.card.card-table.min-height-350 .table-responsive {
    min-height: 350px;
}

.card-table .table thead {
    background-color: #333;
    color: #fff;
}

.card-table .table tbody {
    background-color: #fff;
    min-height: 450px;
}

.card-table .table thead th {
    border-bottom-width: 1px;
    border-color: #453d3d;
}

.card-table .table tbody td, .card-table .table tbody th {
    font-size: 12px;
    vertical-align: middle;
}
