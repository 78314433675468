/* .fileInput {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
}

.cardArquivo {
    margin-bottom: 25px !important;
}

#cardHeaderDocumentos{
    padding: .75rem .25rem !important;
}

.thumbImg {
    width: 60% !important;
}

.statusDocStyle {
    color: var(--dommus-color-white) !important;
    text-align: center !important;
} */

.newFileStyle{
    display: none !important;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #e9ecef;
    text-align: center;
    margin-bottom: 0px !important;
}

.card_arquivo_body{
    padding: 0px !important;
}

.infoDocs {
    margin-left: 5px;
    position:relative;
    top:0;
    transition: all .2s ease-in-out
    /* padding:4px; */
}
.infoDocs:hover{
    cursor: pointer;
    top:-2px;
}