.App {
  text-align: center;
  background: #000 url('../../assets/background.webp') no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
  height: 100vh;
  width: unset;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bntFooter{
  margin: 40% 0% 0% 80%;
  border-radius: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 1366px){
  .App{
    height: 100vh;
    width: unset;
  } 

  .bntFooter{
    margin: 455px 0% 0% 55%;
    border-radius: 50px;
  }

  .iniciar {
    float: right !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.iniciar {
  color: #fff;
  background-color: #ff7600;
  border-color: #ff7600;
  border-radius: 10px;
  padding: 10px 66px;
  font-weight: bold;
  text-align: center;
}

.avancar{
  background-color: #ff7600 !important;
  border-color: #ff7600 !important;
  color: #fff !important;
}

.modal-footer{
  display: flex !important;
  justify-content: space-between !important;
}

.btnPossouCadastro{
  color: #fff !important;
  padding: 10px 9px !important;
  text-align: left !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  /* margin-top: 5px !important; */
  /* border: 2px solid  #d6a802 !important; */
}

.BtnIniciarDisabled {
  opacity: .65;
}

.link {
  color: #fff;
}
.link:hover {
  color: #fff;
  text-decoration:none;
}
.App-link {
  color: #61dafb;
}

.modal-title{
  color: #212529;
}

.p-modal{
  color: #212529;
  /* font-weight: bold; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.efeitoAbreModal{
  transition: all 300ms ease;
 
}


.modal-content{
  /* background-color: # !important; */
}

.modal-title{
  margin: 0;
}

.contentModalInicio {
  /* background-color: #3AAFAB; */
  box-sizing: content-box;
}

.meuFormModal label {
  /* font-family: Apple Chancery, cursive; */
  /* max-width: 220px;
  padding: 8px;
  font-weight: bold;
  letter-spacing: .09em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: -5px !important; */
}

.cardInfosTipos{
  width: 282px !important; 
}

.cardInfosTipos1{
  width: 435px !important; 
}

.cardInfosTipos2{
  width: 335px !important;
}

.cardInfosTiposBody{
  padding: 0.95rem !important;
}

.infosTipos{
  background-color: rgba(0, 0, 0, 0.76)!important;
  color: wheat;
}
.cardInfosTiposTitle{
  margin-top: 0px !important;
  font-size: 15px;
  font-weight: bold;
}

.cardInfosTiposText{
  font-size: 11px;
  font-weight: bold;
}

.iconIfonsTipos{
  margin-left: 10px;
  float: right;
}

.tipologiaStyle {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0px!important;

}

.divprincipalModal{
  margin: 0px;
  width: 100%;
}

.contentModal{
  display: flex;
  flex-direction: row !important;
}

.modal-lg, .modal-xl {
  max-width: 1290px !important;
}

.modal-body{
  padding: 10px !important;
}

.termoAceite{
  text-decoration: underline;
}

.divimgModal {
  /* height: 475px !important;  */
  background-image: url("../../assets/tentativa-desesperada.jpg");
  background-repeat: no-repeat;
  /* 
    background-attachment: fixed; 
    margin: 0; 
  */
  width: 34rem;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background-position: center center;
  /* 
  /* border: 1px solid #000; */
 /* margin-top: 4rem !important;*? */
}

.divimgModalProcessoExiste{
  background-image: url("../../assets/prime.jpg");
  background-repeat: no-repeat;
  width: 34rem;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background-position: center center;
}