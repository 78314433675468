.listaMensagens button{
    margin-left: unset !important;
    
}

.inputMensagem:focus{
    box-shadow: unset;
}

.enviarMensagem{
    height: calc(1.5em + .75rem + 2px);
    font-size: 16px;
}

input[type="file"]#file-upload {
    display: none;
}
/* .custom-file-upload {
    font-size: 16px;
    background-color: #0A71B2 ;
    border: 1px solid #0A71B2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
} */